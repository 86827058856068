<template>
	<div class="wall2">
		
		<!-- <img src="../../assets/img/welimg.png" class="login_logo" alt=""> -->
		<div class="logintype" style="margin-top: 80px;">			
			<router-link to="/login" class="item active">{{ $t("header.GRDL") }}</router-link>
			<router-link to="/login_QY" class="item">{{ $t("header.QYDL") }}</router-link>
		</div>
		<div class="login_box">
			<div class="login_input">
				<img src="../../assets/img/login_msg.png" class="input_img" alt="">
				<input type="text" class="input" :placeholder="$t('ACCOUNTTIP')" v-model="account">
			</div>
			<div class="login_input">
				<img src="../../assets/img/login_psd.png" class="input_img" alt="">
				<!-- <input @keyup.enter.native="login" type="password" class="input" :placeholder="$t('PWDTIP')" v-model="password"> -->
				<el-input @keyup.enter.native="login" show-password class="input" :placeholder="$t('PWDTIP')" v-model="password"></el-input>
			</div>
			<el-button type="primary" @click="login"><!-- 立即登录 -->{{$t('login.LJDL')}}</el-button>
		</div>
		<div class="login_text">
			<el-checkbox v-model="checked">
				{{$t('login.BCDLZT')}}
			</el-checkbox>
			<router-link to="/forget">
				<span class="red"><!-- 忘记登陆密码 -->{{$t('login.WJDLMM')}}</span>
			</router-link>
			
		</div>
		<div class="login_title">
			<!-- 其他登陆方式 -->
			<div>
				<div>{{$t('login.QTDLFS')}}</div>
				<div>{{$t('login.QTDLFS2')}}</div>
			</div>
		</div>
		<div class="login_list">
			<img src="../../assets/img/google.png" @click="login_google" class="face" alt="">
			<!-- <img src="../../assets/img/facebook.png" @click="login_fb" class="face" style="width: 182px;" alt=""> -->
			<img src="../../assets/img/linked.png" class="face" @click="login_link" style="width: 133px;" alt="">
			<img src="../../assets/img/yahoo.png" @click="login_yahoo" class="face" style="width: 123px;" alt="">
		</div>
		<!-- <div id="fb-root"></div>
		
		<div class="fb-login-button" data-width="sdfgdfg" data-size="large" data-button-type="login_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div> -->
		<router-link to="/register" class="login_btn">
			
			<img src="../../assets/img/add.png" class="add" alt="">
			<!-- 创建新账号 -->{{$t('login.CJXZH')}}
			
		</router-link>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				account:'',
				password:'',
				checked:true,
				code:''
			};
		},
		created() {
			// let _this=this;
			
			//     FB.init({
			//       appId      : '1447839949314836',
			//       cookie     : true, // Enable cookies to allow the server to access the session.
			//       xfbml      : true, // Parse social plugins on this webpage.
			//       version    : 'v17.0' // Use this Graph API version for this call.
			//     });
			// 	 FB.getLoginStatus(function(response) { // Called after the JS SDK has been initialized.
			// 		console.log('response1',response);
			// 	      _this.statusChangeCallback(response); // Returns the login status.
			// 	});
			// 	console.log('fb.init');
			//    window.fbAsyncInit = function() {};
			if(this.$route.query.account){
				if(this.$route.query.token){
					this.$local.set('t_k',this.$route.query.token);				
					this.$emit('login',{user_role:0});
					this.$router.push({path:'/home'});
				}else{
					this.$router.push({path:'/GR_form',query:{account:this.$route.query.account}});
				}
			}
		},
		methods:{
			
			async login_link(){
				let res=await this.$request.get('/api/custauth/getLinkedinURL',{redirect_uri:'https://yours.online/app/tencent/callback',user_role:0});
				 window.location.href=res.data.url;
			},
			async login_google(){
				let res=await this.$request.get('/api/custauth/getGoogleURL',{redirect_uri:'https://yours.online/app/tencent/callback/type/google',user_role:0});
				 window.location.href=res.data.url;
			},
			async login_yahoo(){
				let res=await this.$request.get('/api/custauth/getYahoolURL',{redirect_uri:'https://yours.online/app/tencent/callback/type/yahoo',user_role:0});
				 window.location.href=res.data.url;
			},
			async login_fb(){
				let res=await this.$request.get('/api/custauth/getFacebookURL',{redirect_uri:'https://yours.online/app/tencent/callback/type/facebook',user_role:0});
				 window.location.href=res.data.url;
			},
			login_fb2(){				
				FB.login(function(ret) {
					FB.api('/me',{fields: 'name, email'}, function(response) {
						console.log('response',response);
					});
					// console.log('ret',ret);
				},{scope:'email',return_scopes:true});
			},
			
			async login(){
				
				let res=await this.$request.post('/api/custauth/login',{account:this.account,password:this.password,keep_method:this.checked?1:0});	
				this.$message({
					message:res.message,
					type:'success'
				});
				this.$local.set('t_k',res.data);
				
				
				
				this.$emit('login',{user_role:0});
				this.$router.push({path:'/home'});
				
			},
			
			async getInfo(){
				let res=await this.$request.get('/api/custuser/getPersionInfo');	
				
			},
		}
	};
</script>

<style lang="less" >
	
	.logintype{display: flex;margin-top: 30px;
		.item{margin:0 20px;font-size: 18px;font-weight: bold;color: #333;padding-bottom: 15px;}
		.active{color:#567BB6; position: relative;
			&::after{content:"";width: 20px;height: 3px;background-color: #567BB6;border-radius: 5px; position: absolute;left: 0;right: 0;bottom: 0;margin:auto;}
		}
	}
	.wall2{
		// padding: 30px;
		height: 100%;
		background-color: #F3F1EA;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 100px;
		.login_logo{
			
			margin-top: 90px;
		}
		.login_box{
			width: 1329px;
			height: 103px;
			background: #FFFFFF;
			box-shadow: 0px 10px 26px 0px rgba(129,128,125,0.11);
			border-radius: 7px;
			margin-top: 70px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 20px;
			padding-right: 13px;
			box-sizing: border-box;
			.el-button{
				width: 189px;
				height: 77px;
				background-color: #567BB6;
				border: none;
			}
			.login_input{
				display: flex;
				align-items: center;
				width: 477px;
				height: 80px;
				.input_img{
					width: 30px;
					height: 30px;
					margin-right: 17px;
				}
				.el-input__inner,.input{
					border: none;
					font-size: 14px;
					width: 400px;
					height: 80px;
					
				}
				
				input::-webkit-input-placeholder{color:#B3C8E9;}
				
				input::-moz-placeholder{color:#B3C8E9;}
				
				input:-ms-placeholder{color:#B3C8E9;}
			}
		}
		.login_text{
			width: 1329px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-top: 10px;
			font-size: 14px;
			el-checkbox{
				height: 20px;
			}
			.red{
				line-height: 20px;
				color: #B65664!important;
				margin-left: 40px;
			}
		}
		.login_title{
			width: 1329px;
			padding: 0 37px;
			box-sizing: border-box;
			color: #567BB6;
			font-size: 21px;
			padding-top: 70px;
			display: flex;align-items: center;justify-content: center;text-align: center;line-height: 1.6;
			&::before,&::after{
				content:"";border-bottom: 1px solid #567BB6;flex:1;
			}
			&::before{margin-right: 20px;}
			&::after{margin-left: 20px;}
		}
		.login_list{
			
			justify-content: space-between;
			display: flex;
			align-items: center;
			padding-top: 30px;
			.face{
				width: 140px;
				cursor: pointer;
				margin: 0 60px;
			}
		}
		.login_btn{
			min-width: 360px;
			padding:0 15px;
			height: 60px;
			background: #F3F1EA;
			border-radius: 7px;
			border: 1px solid #567BB6;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 22px;
			color: #567BB6;
			margin-top: 90px;
			.add{
				width: 21px;
				height: 21px;
				margin-right: 10px;
			}
		}
	
	}
</style>